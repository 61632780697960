import Vue from 'vue'
import VueRouter from 'vue-router'
import $ from 'jquery'
import {Message} from 'element-ui'
import {getReqUserInfo} from '@/network/welfareMall_api'
import {userLink,cookie,homeLink} from '@/network/request'
import store from '../store/index'

Vue.use(VueRouter)

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: '/welfareIndex'
  },
  {
    path: '/welfareIndex',
    name: 'WelfareIndex',
    component: ()=>import('views/welfareMall/WelfareIndex'),
    redirect: '/welfareHome',
    meta:{},
    children: [
      //首页
      {
        path: '/welfareHome',
        name: 'WelfareHome',
        component: ()=>import('views/welfareMall/WelfareHome'),
        meta:{
          title:'【企业商城】企业福利|员工福利|弹性福利|福利平台|节日福利|生日福利|员工福利兑换-壹人事企业人事服务云平台',
        }
      },
      //搜索结果页
      {
        path: '/welfareSearchResult',
        name: 'WelfareSearchResult',
        component: ()=>import('views/welfareMall/WelfareSearchResult'),
        meta: {
          title: '搜索结果页',
          keepAlive: true,//需要被缓存
        }
      },
      //礼包列表页
      {
        path: '/welfareSetMealList',
        name: 'WelfareSetMealList',
        component: ()=>import('views/welfareMall/WelfareSetMealList'),
        meta: {
          title: '[壹人事]福利商城-节日福利礼品选购',
        }
      },
      //礼包详情页
      {
        path: '/welfareSetMealDetails',
        name: 'WelfareSetMealDetails',
        component: ()=>import('views/welfareMall/WelfareSetMealDetails'),
        meta: {
          //title: '礼包详情',
        }
      },
      //购物车
      {
        path: '/welfareShoppingCart',
        name: 'WelfareShoppingCart',
        component: ()=>import('views/welfareMall/WelfareShoppingCart'),
        meta: {
          title: '购物车',
        }
      },
      //商品详情页
      {
        path: '/WelfareProductDetails',
        name: 'WelfareProductDetails',
        component: ()=>import('views/welfareMall/WelfareProductDetails'),
        meta: {
          title: '商品详情页',
        }
      },
      //企业采集 商品列表页
      {
        path: '/productList',
        name: 'productList',
        component: ()=>import('views/enterpriseCollection/productList'),
        meta: {
          title: '商品列表-壹人事企业商城',
        }
      },
       //企业采集 商品详情页
       {
        path: '/productDetails',
        name: 'productDetails',
        component: ()=>import('views/enterpriseCollection/productDetails'),
        meta: {
          title: '[壹人事]福利商城-商品详情',
        }
      },
    ]
  },  
]

function loginOut(){
    $.ajax({
      url: `${userLink}/Account/LogoutOffice?client=officesite`,
      type: 'get',
      timeout: 5000,
      dataType: 'JSONP',  // 处理Ajax跨域问题
      data: {callback:"?"},
      // 默认情况下，标准的跨域请求是不会发送cookie的
      xhrFields: {
          withCredentials: true
      },
      success: function () {
          let date = new Date();
          date.setTime(date.getTime() - 1);
          let delValue = cookie;
          if (!!delValue) {
              document.cookie = 'pcToken='+delValue+';expires='+date.toGMTString();
          }
          localStorage.removeItem("tokenExpiration");
          localStorage.removeItem("tokenClient");
      }
  })
}

async function pageLoginUser(){
  if(!cookie) return;
  let res = await getReqUserInfo();
  if(res.code == 200){
    let userData = res.data;
    store.commit('saveUseInfo',userData);
    store.commit('saveCompanyInfo',userData.userInfo);
    // 判断账号信息
    if(userData.userType=="C"){
      // 购物车数据
      store.dispatch('addCartAsync',store);
    }else{
      if(userData.companyList){
        if(userData.companyList.length>0){
          store.commit('saveCompanyInfo',userData.companyList[0]);
          // store.dispatch('addCartAsync',store);
        }else if(pageName == 'WelfareShoppingCart'&&userData.companyList.length==0) {
          store.commit("openAddCompany");  
        }
      }else{
        console.log(userData)
      }
    }
  }else{
    Message.warning(res.msg);
    loginOut();
    setTimeout(() => {
      location.href = `${homeLink}`, '_blank';
    }, 500);
    return;
  }
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
let pageName="";
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  pageName = to.name;
  pageLoginUser();
  if (to.meta.title) {
    document.title = to.meta.title
  }
  window.scrollTo(0,0);
  next();
  })
export default router
